import React from "react";
import CardGrid from "..";
import { Image } from "../../../types";
import TileCard from "../cards/tile-card";

interface TileCardGridProps {
  title?: string;
  tiles?: TileCardItem[];
}

export interface TileCardItem {
  title: string;
  image: Image;
  path: string;
  country: string;
}

const TilesCardGrid: React.FC<TileCardGridProps> = ({ tiles, title }) => {
  return (
    <CardGrid title={title} extraClassName="card-grid--4">
      <>{tiles && tiles.map((tile, i) => <TileCard key={i} title={tile.title} image={tile.image} path={tile.path} />)}</>
    </CardGrid>
  );
};

export default TilesCardGrid;
