import React from "react";
import { Image } from "../../../../types";
import { parse } from "../../../../utils/component";
import { addWidthParameter } from "../../../../utils/image-utils";
import Link from "../../../link";

interface TileCardProps {
  path?: string;
  image?: Image;
  title?: string;
}

const TileCard: React.FC<TileCardProps> = ({ title, image, path }) => {
  return (
    <div className="tilecard">
      <Link path={`/${path}`} className="tilecard__anchor" title={title}>
        <div className="tilecard__header">
          {image && <img src={addWidthParameter(parse(image?.url), 40)} className="tilecard__header-image" alt={parse(image?.altText)} />}
        </div>
        <div className="tilecard__body">
          <h4 className="tilecard__heading">{title}</h4>
        </div>
      </Link>
    </div>
  );
};

export default TileCard;
