import { graphql, PageProps } from "gatsby";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import Seo from "../../components/seo";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import GlobalContext from "../../contexts/global-provider";
import { PackageProvider } from "../../contexts/package-provider";
import { TideItemForSporten, TideItemForToernooi, TideItemForUspFolder, TideItemForWebsite, TideItemForWildcardTournamentPage } from "../../types";
import { getParentItemWithTypename, parse } from "../../utils/component";
import MapTournamentPageComponents from "../../utils/mappers/map-tournament-page-components";

export interface TournamentPageTemplateData {
  website: TideItemForWebsite;
  page: TideItemForToernooi;
  usps: TideItemForUspFolder;
  wildcardTournamentPage: TideItemForWildcardTournamentPage;
}

interface TournamentPageTemplateContext {
  language: string;
  breadcrumbs: Breadcrumb[];
  tournamentIds: string[];
}

const TournamentPageTemplate: React.FC<PageProps<TournamentPageTemplateData, TournamentPageTemplateContext>> = ({
  data,
  pageContext: { language, breadcrumbs, tournamentIds },
}) => {
  const globalContext = useContext(GlobalContext);
  const [allotmentTagIds, setAllotmentTagIds] = useState<number[]>([]);
  const [cancelPackagesSearch, setCancelPackagesSearch] = useState<boolean>(false);
  const [preventDoubleCall, setPreventDoubleCall] = useState(false);
  const { games, catalogueIds, notificationBar, primaryNavigation, setGlobalData } = useContext<ComponentContextType>(ComponentContext);

  const sport = getParentItemWithTypename("TideItemForSporten", data.page) as TideItemForSporten;
  const catalogueId = sport?.content?.general?.catalogue ? Number(sport.content?.general?.catalogue[0]) : undefined;

  useEffect(() => {
    (async () => {
      if (globalContext && globalContext.language !== language) {
        globalContext.setLanguage(language);
      }
      if (!games || games.length == 0) {
        await setGlobalData();
      }
    })();
  }, []);

  useEffect(() => {
    if (games && !isEmpty(catalogueIds) && !preventDoubleCall) {
      setPreventDoubleCall(true);
      if (data.page.content?.general?.tag?.tideId) {
        setAllotmentTagIds([data.page.content.general.tag.tideId]);
      } else {
        setCancelPackagesSearch(true);
      }
    }
  }, [games, catalogueIds]);

  return (
    <>
      {primaryNavigation && (
        <Layout>
          <Seo
            title={parse(data.page.content?.seo?.seoTitle)}
            description={parse(data.page.content?.seo?.seoDescription)}
            keywords={parse(data.page.content?.seo?.seoKeywords)}
            website={parse(data.website)}
            breadcrumbs={parse(breadcrumbs)}
          />
          {!isEmpty(notificationBar?.content?.general?.notification) && (
            <NotificationBar
              text={notificationBar?.content?.general?.notification ?? ""}
              icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
            />
          )}
          <Navbar website={parse(data.website)} />
          {globalContext.language === language && games.length > 0 && (
            <PackageProvider allotmentTagIds={allotmentTagIds} catalogueId={catalogueId} cancelPackagesSearch={cancelPackagesSearch}>
              <MapTournamentPageComponents
                website={data.website}
                page={data.page}
                usps={data.usps}
                breadcrumbs={breadcrumbs}
                tournamentIds={tournamentIds}
                wildcardTournamentPage={data.wildcardTournamentPage}
              />
            </PackageProvider>
          )}
          <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
        </Layout>
      )}
    </>
  );
};

export default TournamentPageTemplate;

export const query = graphql`
  query TournamentPageQuery($pageId: String, $websiteId: String) {
    wildcardTournamentPage: tideItemForWildcardTournamentPage(parentItem: { id: { eq: $websiteId } }) {
      __typename
      id
      name
      childItems {
        ...TideItemForHeroComponentFragment
        ...TideItemForPageTitleComponentFragment
        ...TideItemForIntroComponentFragment
        ...TideItemForOutroComponentFragment
        ...TideItemForOverviewComponentFragment
      }
    }
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    page: tideItemForToernooi(id: { eq: $pageId }) {
      name
      id
      parentNodeId
      content {
        general {
          hero {
            altText
            title
            url
          }
          thumbnail {
            altText
            title
            url
          }
          logo {
            altText
            title
            url
          }
          intro
          title
          listtitle
          path
          hidePopularClubs
          tag {
            tideId
          }
        }
        outro {
          text
          title
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
        kiyoh {
          name
        }
      }
      parentItem {
        name
        ... on TideItemForSporten {
          __typename
          content {
            general {
              catalogue
            }
          }
        }
      }
      childItems {
        ...BlocksFragment
        ...TideItemForQuickLinksSectionFragment
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
  }
`;
