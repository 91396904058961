import { graphql } from "gatsby";
import { first, get, isNil, uniq } from "lodash";
import React, { useContext } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import TilesCardGrid, { TileCardItem } from "../../components/cardgrid/tile-cardgrid";
import Hero from "../../components/hero";
import Intro from "../../components/intro/wildcard";
import Reviews from "../../components/reviews";
import SingleTextColumn from "../../components/singlecolumn/single-text-column";
import {
  TideItem,
  TideItemForHeroComponent,
  TideItemForIntroComponent,
  TideItemForNavigationSection,
  TideItemForOutroComponent,
  TideItemForOverviewComponent,
  TideItemForPageTitleComponent,
  TideItemForSeizoenWedstrijd,
  TideItemForTeam,
  TideItemForTestimonialsComponent,
  TideItemForToernooi,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWildcardTournamentPage,
} from "../../types";
import { getChildItemsInTypenameArray, getChildItemsWithTypename, getSportName, parse } from "../component";
import FacetedSearch from "../../components/facetedsearch";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { FilterProvider } from "../../contexts/filter-provider";
import MapBlocksComponents, { BlockTypes } from "./map-blocks-components";
import PageTitle from "../../components/page-title";

interface MapTournamentPageComponentsProps {
  website: TideItemForWebsite;
  page: TideItemForToernooi;
  usps: TideItemForUspFolder;
  breadcrumbs: Breadcrumb[];
  tournamentIds: string[];
  wildcardTournamentPage: TideItemForWildcardTournamentPage;
}

const MapTournamentPageComponents: React.FC<MapTournamentPageComponentsProps> = ({
  website,
  page,
  usps,
  breadcrumbs,
  tournamentIds,
  wildcardTournamentPage,
}) => {
  const { teams, games } = useContext<ComponentContextType>(ComponentContext);
  let teamsForTournament = [] as TideItemForTeam[];
  const teamsForSport = teams.filter((team) => getSportName(team) && getSportName(team).toLowerCase() === page.parentItem?.name.toLowerCase());
  if (teamsForSport.length !== 0) {
    const gamesForTournament = games.filter((game) => {
      const seasonGames = getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[];
      if (seasonGames.some((sg) => sg.content?.general?.tournamentId && tournamentIds.includes(sg.content.general.tournamentId))) {
        return true;
      }
      return false;
    });
    const homeTeamIds = gamesForTournament.map((g) => g.content?.general?.homeTeamId);
    const awayTeamIds = gamesForTournament.map((g) => g.content?.general?.awayTeamId);
    const teamIds = uniq([...homeTeamIds, ...awayTeamIds]);
    teamsForTournament = teamsForSport.filter((team) => teamIds.includes(team.content?.general?.id) && team.content?.general?.popular);
  }
  const tournamentInfoBlocks = getChildItemsInTypenameArray(BlockTypes, parse(page)) as any[];

  const componentRenderers = {
    TideItemForHeroComponent: (componentItem: TideItemForHeroComponent) => {
      const quickLinksSection = first(getChildItemsWithTypename("TideItemForNavigationSection", page) as TideItemForNavigationSection[]);
      return (
        <Hero
          key={componentItem.id}
          image={parse(page.content?.general?.hero)}
          breadcrumbs={breadcrumbs}
          showQsm={parse(componentItem.content?.general?.showQsm)}
          qsmSlimVersion={true}
          extraClass="header--list-page"
          quickLinksSection={quickLinksSection}
        />
      );
    },
    TideItemForPageTitleComponent: (componentItem: TideItemForPageTitleComponent) => {
      return <PageTitle key={componentItem.id} title={parse(page.content?.general?.title)} image={parse(page.content?.general?.logo)} />;
    },
    TideItemForIntroComponent: (componentItem: TideItemForIntroComponent) => {
      return (
        <Intro
          key={componentItem.id}
          text={parse(page.content?.general?.intro)}
          // title={parse(page.content?.general?.title)}
          website={parse(website)}
          usps={parse(usps)}
        />
      );
    },
    TideItemForOverviewComponent: (componentItem: TideItemForOverviewComponent) => {
      const type = first(componentItem.content?.general?.type);
      switch (type) {
        case "Clubs":
          if (teamsForTournament.length !== 0 && page.content?.general?.hidePopularClubs !== true) {
            const tileCardItems = teamsForTournament
              .sort((a, b) => {
                const orderA = a.content?.general?.sortingorder;
                const orderB = b.content?.general?.sortingorder;

                if (orderA === undefined || orderA === null) return 1;
                if (orderB === undefined || orderB === null) return -1;
                return orderA - orderB;
              })
              .map(
                (team) =>
                ({
                  title: team.name,
                  image: team.content?.general?.logo,
                  path: "clubs/" + team.content?.general?.path,
                } as TileCardItem)
              );
            return <TilesCardGrid key={componentItem.id} tiles={tileCardItems} />;
          }
          return;
        case "Packages":
          return (
            <FilterProvider key={componentItem.id}>
              <FacetedSearch page="tournaments" itemsPerPage={6}></FacetedSearch>
            </FilterProvider>
          );
        default:
          return <></>;
      }
    },
    TideItemForOutroComponent: (componentItem: TideItemForOutroComponent) => {
      return (
        <>
          <SingleTextColumn
            key={componentItem.id}
            title={parse(page.content?.outro?.title)}
            text={parse(page.content?.outro?.text)}
            centered={true}
          />
          <MapBlocksComponents blocks={parse(tournamentInfoBlocks as TideItem[])} key={2} games={[]} />
        </>
      );
    },
    TideItemForTestimonialsComponent: (componentItem: TideItemForTestimonialsComponent) => {
      return (
        <Reviews
          key={componentItem.id}
          testimonials={componentItem}
          specificReviewName={page.content?.kiyoh?.name ? `review ${page.content.kiyoh.name}` : undefined}
        />
      );
    },
  };

  return (
    <>
      {wildcardTournamentPage?.childItems?.map((tideItem) => {
        const typeName = get(tideItem, "__typename");
        const renderer = get(componentRenderers, typeName);

        if (!isNil(renderer)) {
          return renderer(tideItem);
        }

        return null;
      })}
    </>
  );
};

export default MapTournamentPageComponents;

export const query = graphql`
  fragment TideItemForOverviewComponentFragment on TideItemForOverviewComponent {
    __typename
    id
    content {
      general {
        title
        type
      }
    }
  }
`;
